import React, { useState, useEffect, useContext } from 'react';
import GeotabContext from '../contexts/Geotab';

const DevicesPage = () => {
  const [context] = useContext(GeotabContext);
  const [devices, setDevices] = useState([]);
  const [tpmsData, setTpmsData] = useState([]);

  const { geotabApi, logger } = context;

  useEffect(() => {
    geotabApi.call('Get', {
      typeName: 'IoxAddOnStatus',
    }, (result) => {
      logger.log(`Loaded ${result.length} Iox Addons`);
      setDevices(result);
    }, (error) => {
      logger.error(error);
    });
  }, []);

  useEffect(() => {
    geotabApi.call('GetFeed', {
      typeName: 'TextMessage',
      fromVersion: null,
      resultsLimit: 1000,
    }, (result) => {
      logger.log(`Loaded ${result.length} TextMessages`);
      setTpmsData(result);
    }, (error) => {
      logger.error(error);
    });
  }, []);
  return (
    <div>
      <div className="geotabPageHeader">
        <h1 className="geotabPageName">
          Devices
        </h1>
      </div>
      <div className="geotabSecondaryFill">
        <ul>
          {devices.map((device, ndx) => <li key={ndx}>{JSON.stringify(device)}</li>)}
        </ul>
      </div>
      <div className="tpms">
        <h1>Tpms data</h1>
        <div style={{ width: '100%', outline: "1px solid red", fontFamily: "monospace", fontSize: 12 }}>
          {tpmsData.map((t, ndx) => <div key={ndx}>{JSON.stringify(t)}</div>)}
        </div>
      </div>
    </div>
  );
};

export default DevicesPage;
